
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Pagination, Skeleton, Table, } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { NameOf, NotificationUtil, StringUtil, TableColumnBuilder } from 'src/utils';
import MappingPickListItemModel from 'src/models/frontend/MappingPickListItemModal';
import MappingPickListModel from 'src/models/frontend/MappingPickListModel';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import ReloadIconSVG from '../svgs/ReloadIconSVG';

export interface PickListSelectorModalProps {
    open: boolean;
    assignment: MappingItemAssignmentDTO | null;

    onCancel?: () => void;
    onSelect?: (value: MappingPickListItemModel) => void;
    onChange?: (value: MappingPickListItemModel) => void;
}

const PickListSelectorModal: React.FC<PickListSelectorModalProps> = (props) => {
    const authContext = useContext(AuthenticationContext);
    const cacheContext = useContext(CacheContext);
    const [selectedValue, setSelectedValue] = useState<MappingPickListItemModel | null>(null);
    const [columnData, setColumnData] = useState<MappingPickListModel | null>(null);
    const [tableData, setTableData] = useState<MappingPickListItemModel[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!props.open) {
            unloadModalData();
            return;
        }
        loadTableData();
    }, [props.assignment]);


    const loadTableData = async (reload?: boolean) => {
        setLoading(true);
        try {
            // Get the pickList from the cache component
            const results = await cacheContext.getPickList(authContext.account!.id, authContext.location!.id, props.assignment!.pickListType, reload);

            // Split the data out for easier management
            const newColumnData: MappingPickListModel = { ...results, pickListItems: [] };
            let newTableData: MappingPickListItemModel[] = [...results.pickListItems];

            // If we have a required filter, then we will pre-filter the data
            if (props.assignment?.pickListItemTypeFilterRequired) {
                const filter = props.assignment?.pickListItemTypeFilter || '';
                newTableData = newTableData.filter(x => x.columnItemTypeValue.localeCompare(filter, undefined, { sensitivity: 'base' }) === 0);
            }

            // If we are given a default selected value, update our selection
            // We will support reload in the future, so we want to check that this is only done once
            if (props.assignment?.selectedItemName != null && (selectedValue == null || props.assignment?.displayValue === selectedValue.returnValue)) {
                const found = newTableData.find(x => props.assignment?.displayValue!.localeCompare(x.returnValue, undefined, { sensitivity: 'base' }) === 0);
                if (found != null) {
                    setSelectedValue(found);
                }
                else {
                    setSelectedValue(null);
                }
            }
            setColumnData(newColumnData);
            setTableData(newTableData);
        } catch (error) {
            let accountingSystem = (await cacheContext.getIntegrations(authContext.account!.id, authContext.location!.id))[0]?.displayName;
            NotificationUtil.error({
                key: 'PickListSelectorModal',
                message: 'Error while loading PickList Items',
                error: `Unable to communicate with ${accountingSystem}. Reconnect to ${accountingSystem} by going to Integrations under the Settings tab.`
            });
        }
        setLoading(false);
    };

    const unloadModalData = () => {
        setSelectedValue(null);
        setColumnData(null);
        setTableData([]);
        setLoading(false);
    };

    const handleSelectValue = (records: MappingPickListItemModel[]) => {
        setSelectedValue(records[0]);
        props.onChange && props.onChange(records[0]);
    };

    const handleDoubleClick = () => {
        // First click should fire the handleSelectValue, so all we need to do is confirm that it has been selected
        if (selectedValue != null) {
            props.onSelect?.(selectedValue);
        }
    };

    const handleModalSubmit = () => {
        if (selectedValue != null) {
            props.onSelect?.(selectedValue);
        }
    };

    const handleModalCancel = () => {
        // All we need to do is close, hopefully the caller is listening!
        props.onCancel && props.onCancel();
    };

    const handleModalReset = () => {
        props.onSelect?.(MappingPickListItemModel.create());
    };

    const handleReload = () => {
        if (loading) {
            return;
        }
        loadTableData(true);
    };

    const column1 = TableColumnBuilder.Create<MappingPickListItemModel>('column1Value', columnData?.labelColumn1)
        .AddSorter('Text')
        .AddTextFilterer()
        .AddRenderer('Ellipses')
        .Build();
    const column2 = TableColumnBuilder.Create<MappingPickListItemModel>('column2Value', columnData?.labelColumn2)
        .AddSorter('Text')
        .AddTextFilterer()
        .AddRenderer('Ellipses')
        .Build();
    const column3 = TableColumnBuilder.Create<MappingPickListItemModel>('column3Value', columnData?.labelColumn3)
        .AddSorter('Text')
        .AddTextFilterer()
        .AddRenderer('Ellipses')
        .Build();
    let column4Config = TableColumnBuilder.Create<MappingPickListItemModel>('columnItemTypeValue', columnData?.labelTypeColumn)
        .AddSorter('Text')
        .AddRenderer('Ellipses')
        .DefaultFilteredValue(props.assignment?.pickListItemTypeFilter != null ? [props.assignment?.pickListItemTypeFilter.toLowerCase()] : undefined);
    if (!props.assignment?.pickListItemTypeFilterRequired) {
        column4Config = column4Config.AddEnumFilterer([...new Set(tableData.map(x => x.columnItemTypeValue))].map(x => ({ text: x.toLowerCase(), value: x.toLowerCase() })), 'Exact Text');
        if (props.assignment?.pickListItemTypeFilter != null && (tableData.findIndex(x => x.columnItemTypeValue.toLowerCase() == props.assignment?.pickListItemTypeFilter.toLowerCase()) == -1)) {
            column4Config.DefaultFilteredValue(undefined);
        }
    }
    let column4 = column4Config.Build();

    let tableColumns: ColumnProps<MappingPickListItemModel>[] = [
        columnData?.showColumn1 && column1,
        columnData?.showColumn2 && column2,
        columnData?.showColumn3 && column3,
        columnData?.showListTypeColumn && column4
    ].filter(x => x) as ColumnProps<MappingPickListItemModel>[]; // Types are hard




    return (<Modal
        destroyOnClose // Indicates that we do not want to cache anything when reopening the modal
        className='pick-list-selector-modal'
        style={{ top: 150 }}
        width={Math.max(150 * tableColumns.length, 600)} // Variable based on the number of table columns, with a min of 600 when loading
        open={props.open}
        onCancel={handleModalCancel} // Handles clicks outside the modal window
        footer={<>
            <Button type='default' onClick={handleModalCancel}>Cancel</Button>
            <Button type='default' onClick={handleModalReset}>Reset</Button>
            <Button type='primary' onClick={handleModalSubmit} disabled={selectedValue == null}>Select</Button>
        </>}
    >
        <h2>
            {props.assignment?.displayName || 'Select an item'}
            <Button type='link' icon={<ReloadIconSVG />} onClick={() => handleReload()} />
        </h2>
        {!StringUtil.IsNullOrEmpty(props.assignment?.displayDescription) && <p>{props.assignment?.displayDescription || 'Descriptive text here'}</p>}
        {loading && <div className='with-flex-layout' style={{ height: 450 }}><Skeleton active paragraph={{ rows: 8 }} /></div>}
        {!loading && <div className='with-flex-layout' style={{ height: 450 }}>
            {selectedValue != null && <h3 className='make-text-bold'>Currently Selected: {selectedValue.displayValue}</h3>}

            <div className='with-overflow-y' style={{ overflowX: 'hidden' }}>
                <Table
                    rowKey={NameOf<MappingPickListItemModel>('key')}
                    className='less-condensed-table striped-table borderless-table picklist-table'
                    rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
                    rowSelection={{
                        type: 'radio',
                        selectedRowKeys: selectedValue != null ? [selectedValue.key] : [],
                        onChange: (_, rows) => handleSelectValue(rows)
                    }}
                    onRow={record => ({
                        onClick: () => handleSelectValue([record]),
                        onDoubleClick: () => handleDoubleClick(),
                    })}
                    pagination={{
                        showSizeChanger: true,
                        className: "picklist-table-pagination",
                        defaultPageSize: 20,
                    }}
                    scroll={{ y: 310 }}
                    columns={tableColumns}
                    dataSource={tableData}
                />
            </div>
        </div>}
    </Modal>);
};

export default PickListSelectorModal;
