import React, { useContext, useEffect, useState } from 'react';
import { Descriptions, Skeleton, Space, Table, Typography } from 'antd';
import { NameOf, NotificationUtil, TableColumnBuilder, TableExpressions } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import AuditTrailItemDTO from 'src/models/generated/AuditTrailItemDTO';
import AuditController from 'src/api/AuditController';
import AuditTrailItemViewDTO from 'src/models/generated/AuditTrailItemViewDTO';
import { ColumnProps } from 'antd/lib/table';
import AuditTrailItemViewRowDTO from 'src/models/generated/AuditTrailItemViewRowDTO';
import MultiToolPicker from '../MultiToolPicker';
import { tz } from 'moment-timezone';
export interface AuditTrailHistoryItemProps {
    auditTrailItem: AuditTrailItemDTO | null;
}

const AuditTrailHistoryItem: React.FC<AuditTrailHistoryItemProps> = (props) => {
    const authContext = useContext(AuthenticationContext);
    const [componentData, setComponentData] = useState<AuditTrailItemViewDTO | null>(null);
    const [loading, setLoading] = useState(false);

    // This will reset the form when the visibility or defaultValues have changed
    useEffect(() => {
        if (props.auditTrailItem == null) {
            return;
        }
        loadComponentData();
    }, [props.auditTrailItem?.id]);

    const loadComponentData = async () => {
        setLoading(true);
        try {
            const results = await AuditController.getAuditTrailItem(authContext.account!.id, authContext.location!.id, props.auditTrailItem!.id);

            // Of course this has to be sorted differently 😒
            results.data.rows.sort(TableExpressions.NumberSorter('order'));
            results.data.headerAttributes.sort(TableExpressions.NumberSorter('order'));
            results.data.footerAttributes.sort(TableExpressions.NumberSorter('order'));

            setComponentData(results.data);
        } catch (error) {
            NotificationUtil.error({
                key: 'AuditTrailHistoryItem',
                message: 'Error while loading Audit Item',
                error
            });
        }
        setLoading(false);
    };

    const tableColumns = componentData?.columns.map<ColumnProps<AuditTrailItemViewRowDTO>>((data, index) => {
        let ret = TableColumnBuilder.Create<AuditTrailItemViewRowDTO>(data.key as any, data.label)
            .AddRenderer('Ellipses', (record) => <MultiToolPicker value={record.rowValues[index].value} displayType='Label' formatType={record.rowValues[index].valueFormatType} />);
        if (data.label.toLowerCase() === 'order')
            ret.Width(20);
        else if (data.label.toLowerCase() == 'rule filter')
            ret.Width(1000);
        return ret
            .Build();
    });

    if (loading || props.auditTrailItem == null) {
        return <div className='audit-trail-history-item'>
            <Skeleton active />
        </div>;
    }

    return <div className='audit-trail-history-item'>
        {/* Render something here about the history item */}
        <Descriptions column={2} size='small' layout='horizontal' style={{ minWidth: 1000 }}>
            <Descriptions.Item label={<Typography.Text strong>Name</Typography.Text>}>{props.auditTrailItem.name}</Descriptions.Item>
            <Descriptions.Item label={<Typography.Text strong>Created</Typography.Text>}>{props.auditTrailItem.created?.format('ll LTS ') + tz(tz.guess()).zoneAbbr()}</Descriptions.Item>
            <Descriptions.Item label={<Typography.Text strong>Location Name</Typography.Text>}>{props.auditTrailItem.locationDisplayName}</Descriptions.Item>
            <Descriptions.Item label={<Typography.Text strong>UserName</Typography.Text>}>{props.auditTrailItem.userDisplayName}</Descriptions.Item>
        </Descriptions>

        {/* Render header as a table. They have indicated that this will be only a single column, so we are banking on that */}
        <Descriptions column={1} size='small' layout='horizontal' style={{ maxWidth: 1000 }}>
            {componentData?.headerAttributes.map((x, i) => <Descriptions.Item key={i} label={<Typography.Text strong>{x.displayLabel}</Typography.Text>}><MultiToolPicker value={x.displayValue} displayType='Label' formatType={x.displayValueFormatType} /></Descriptions.Item>)}
        </Descriptions>

        <Table
            style={{ margin: '12px 24px', maxWidth: 1300 }}
            rowKey={NameOf<AuditTrailItemViewRowDTO>('order')}
            className='condensed-table striped-table borderless-table'
            rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
            loading={loading}
            pagination={false}
            columns={tableColumns}
            dataSource={componentData?.rows}
        />

        {/* Render footer as a table. Same as header, there is no format type, which is exciting */}
        <Descriptions column={1} size='small' layout='horizontal' style={{ maxWidth: 1000 }}>
            {componentData?.footerAttributes.map((x, i) => <Descriptions.Item key={i} label={<Typography.Text strong>{x.displayLabel}</Typography.Text>}><MultiToolPicker value={x.displayValue} displayType='Label' formatType={'String'} /></Descriptions.Item>)}
        </Descriptions>
    </div>;
};

export default AuditTrailHistoryItem;
