import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Row, Space, Table, Modal, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder, StringUtil, ObjectUtil } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import ReportController from 'src/api/ReportController';
import ReportTransactionDTO from 'src/models/generated/ReportTransactionDTO';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';
import ReportTransactionExpander from './ReportTransactionExpander';
import VoidIconSVG from 'src/components/svgs/VoidIconSVG';
import ApproveIconSVG from 'src/components/svgs/ApproveIconSVG';
import ReportTransactionReferenceDTO from 'src/models/generated/ReportTransactionReferenceDTO';
import ResetIconSVG from 'src/components/svgs/ResetIconSVG';
import { AxiosError } from 'axios';
import { FilterValue, SortOrder } from 'antd/lib/table/interface';
import { tz } from 'moment-timezone';

interface ReportTransactionsProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  refreshTrigger: number;
  refreshTabs: () => void;
}

const ReportTransactions: React.FC<ReportTransactionsProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [transactionIdQueryParam, setTransactionIdQueryParam] = useQueryParam('transactionId');
  const [journalIdQueryParam, setJournalIdQueryParam] = useQueryParam('transactionJournalId');
  const [referenceIdQueryParam, setReferenceIdQueryParam] = useQueryParam('transactionReferenceId');
  const [transactionTypeQueryParam, setTransactionTypeQueryParam] = useQueryParam('transactionType');
  const [transactionStatusQueryParam, setTransactionStatusQueryParam] = useQueryParam('transactionStatus');
  const [transactionTableFiltersQueryParam, setTransactionTableFiltersQueryParam] = useState<string>('');
  const [transactionTableSortedColumnQueryParam, setTransactionSortedColumnQueryParam] = useState<string>('');
  const [transactionTableSortOrderQueryParam, setTransactionSortOrderQueryParam] = useState<string>('');

  const [printingTableQueryParam, setPrintingTableQueryParam] = useState<ReportTransactionDTO[]>([]);

  const [tableData, setTableData] = useState<ReportTransactionDTO[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<ReportTransactionDTO[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (props.startDate != null && props.endDate != null) {
      loadTableData();
    }
  }, [props.startDate, props.endDate]);

  // Handle the filter query params
  useEffect(() => {
    
    let filteredData = [...tableData];
    let hasFilter = false;

    if (transactionIdQueryParam != null) {
      filteredData = filteredData.filter(x => x.transactionId === transactionIdQueryParam);
      hasFilter = true;
    }

    if (journalIdQueryParam != null) {
      filteredData = filteredData.filter(x => x.journalId === journalIdQueryParam);
      hasFilter = true;
    }

    if (referenceIdQueryParam != null) {
      filteredData = filteredData.filter(x => x.displayJournal === referenceIdQueryParam);
      hasFilter = true;
    }

    if (transactionTypeQueryParam != null) {
      filteredData = filteredData.filter(x => x.displayType === transactionTypeQueryParam);
      hasFilter = true;
    }

    if (transactionStatusQueryParam != null) {
      filteredData = filteredData.filter(x => x.actualStatus.toLowerCase() === transactionStatusQueryParam.toLowerCase());
      hasFilter = true;
    }

    setFilteredTableData(filteredData);
    if(!refreshing){
    setExpandedKeys([]);
    setSelectedKeys([]);
    }
    else{
      setRefreshing(false);
    }
  }, [tableData, transactionIdQueryParam, journalIdQueryParam, referenceIdQueryParam, transactionTypeQueryParam, transactionStatusQueryParam]);

  const loadTableData = async () => {
    if (props.startDate == null || props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportTransaction(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      sortedData = sortedData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(sortedData);
      setExpandedKeys([]);
      setSelectedKeys([]);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportTransactions',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const refreshTableData = async () => {
    
    if (props.startDate == null || props.endDate == null) {
      return;
    }
    setRefreshing(true);
    setLoading(true);
    try {
      const results = await ReportController.getReportTransaction(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      sortedData = sortedData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportTransactions',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);

  };

  const mySetExpandedKeys = (keys: React.Key[]) => {
    setExpandedKeys(keys);
  };

  const handleExpanderOnChange = (record: ReportTransactionDTO) => {
    
    refreshTableData();
    props.refreshTabs();
    return;
  };

  const finishApproveClick = async () => {
    setLoading(true);
    try {
      const transactions = tableData.filter(x => selectedKeys.includes(x.transactionId)).map(ReportTransactionReferenceDTO.create);
      const results = await ReportController.getReportTransactionApprove(authContext.account!.id, authContext.location!.id, transactions);

      // Merge the updated rows into the current table data
      let myTableData = [...tableData];
      results.data.forEach(x => myTableData.splice(myTableData.findIndex(y => y.transactionId == x.transactionId), 1, x));
      myTableData = myTableData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(myTableData);
      props.refreshTabs();
    } catch (error) {
      if(error instanceof AxiosError){
        Modal.confirm({
          className: 'multitool-warning-modal',
          title: 'Warning',
          content: error.response? error.response.data: 'Something went wrong',
          okText: 'OK',
          cancelButtonProps: { style: { display: 'none' } }
        });
      }
    }
    setLoading(false);
  };

  const handleApproveClick = async () => {

    let posted = tableData.filter(x => selectedKeys.includes(x.transactionId)).filter(x => x.actualStatus.toLowerCase() == 'posted');
    if(posted.length > 0){
      Modal.confirm({
        title: 'Warning',
          content: 'Some of the selected transactions have the posted status. Are you sure you want to approve them? This may result in duplicate transactions in your accounting system.',
        cancelText: 'No',
        okText: 'Yes',
        onOk: async () => { await finishApproveClick();}
      });
    }
    else{
      finishApproveClick();
    }

  };

  const handleVoidClick = async () => {
    setLoading(true);
    try {
      const transactions = tableData.filter(x => selectedKeys.includes(x.transactionId)).map(ReportTransactionReferenceDTO.create);
      const results = await ReportController.getReportTransactionVoid(authContext.account!.id, authContext.location!.id, transactions);

      // Merge the updated rows into the current table data
      let myTableData = [...tableData];
      results.data.forEach(x => myTableData.splice(myTableData.findIndex(y => y.transactionId === x.transactionId), 1, x));
      myTableData = myTableData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(myTableData);
      props.refreshTabs();
    } catch (error) {
      if(error instanceof AxiosError){
        Modal.confirm({
          className: 'multitool-warning-modal',
          title: 'Warning',
          content: error.response? error.response.data: 'Something went wrong',
          okText: 'OK',
          cancelButtonProps: { style: { display: 'none' } }
          });
        }
    }
    setLoading(false);
  };

  const handleUnapproveClick = async () => {
    setLoading(true);
    try {
      const transactions = tableData.filter(x => selectedKeys.includes(x.transactionId)).map(ReportTransactionReferenceDTO.create);
      const results = await ReportController.getReportTransactionUnapprove(authContext.account!.id, authContext.location!.id, transactions);
      
      // Merge the updated rows into the current table data
      let myTableData = [...tableData];
      results.data.forEach(x => myTableData.splice(myTableData.findIndex(y => y.transactionId === x.transactionId), 1, x));
      myTableData = myTableData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(myTableData);
      props.refreshTabs();
    } catch (error) {
      if(error instanceof AxiosError){
        Modal.confirm({
          className: 'multitool-warning-modal',
          title: 'Warning',
          content: error.response? error.response.data: 'Something went wrong',
          okText: 'OK',
          cancelButtonProps: { style: { display: 'none' } }
      });
    }
      refreshTableData();
    }
    setLoading(false);
  };

  const renderExpandCollapseAll = () => {
    if (expandedKeys.length > 0) {
      return <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={() => (setExpandedKeys([]))}>Collapse All</Button>;
    }
    return <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={() => (setExpandedKeys(tableData.map(x => x.transactionId)))}>Expand All</Button>;
  };

  const renderClearAllFilter = () => {
    let output: React.ReactNode[] = [];
    if (transactionIdQueryParam != null) {

      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='transactionId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Transaction: ${tableData.find(x=> x.transactionId === transactionIdQueryParam)?.displayReference}`}
        onClose={() => setTransactionIdQueryParam(null)}
      />);
    }

    if (journalIdQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='journalId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Journal ID: ${journalIdQueryParam}`}
        onClose={() => setJournalIdQueryParam(null)}
      />);
    }

    if (referenceIdQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='referenceId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Reference: ${referenceIdQueryParam}`}
        onClose={() => setReferenceIdQueryParam(null)}
      />);
    }

    if (transactionTypeQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='transactionType'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Type: ${transactionTypeQueryParam}`}
        onClose={() => setTransactionTypeQueryParam(null)}
      />);
    }

    if (transactionStatusQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='transactionStatus'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Status: ${transactionStatusQueryParam}`}
        onClose={() => setTransactionStatusQueryParam(null)}
      />);
    }

    return output;
  };

  const setKeys = () => {
    localStorage.setItem('transactionSelectedIds', JSON.stringify(selectedKeys));
    localStorage.setItem('transactionExpandedIds', JSON.stringify(expandedKeys));
  };

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string | null][] = [
      ['reportStartDate', props.startDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['reportEndDate', props.endDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['transactionId', transactionIdQueryParam],
      ['transactionJournalId', journalIdQueryParam],
      ['transactionReferenceId', referenceIdQueryParam],
      ['transactionType', transactionTypeQueryParam],
      ['transactionStatus', transactionStatusQueryParam],
      ['transactionFilters', transactionTableFiltersQueryParam],
      ['transactionSortedColumn', transactionTableSortedColumnQueryParam],
      ['transactionSortOrder', transactionTableSortOrderQueryParam],
      ['returnUrl', url.pathname + url.search],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_TRANSACTION_REPORT()} additionalQueryParams={printingQueryParams}>
      <Button onClick={setKeys} style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  const tableColumns: ColumnProps<ReportTransactionDTO>[] = [
    TableColumnBuilder.Create<ReportTransactionDTO>('displayDate', 'Date')
      .Width(100)
      .AddSorter('Date')
      .AddEnumFilterer(tableData.map(x => x.displayDate).sort((a, b) => a?.diff(b) ?? 0).filter(x => x != null && x?.year() > 2000).map(x => x?.format('L') ?? ''), 'Date')
      .AddRenderer('ShortDate')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayType', 'Type')
      .Width(140)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)))
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayJournal', 'Journal')
      .Width(120)
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Custom', (value, record) => <LinkWithQuery disabled={record.displayJournal === ''} to={RouteConfig.REPORTS_JOURNALS()} additionalQueryParams={[['journalId', record.journalId]]} includedQueryParams={['reportStartDate', 'reportEndDate']}>
        <Button type='link'>{value}</Button>
      </LinkWithQuery>)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayReference', 'Reference')
      .Width(130)
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayName', 'Name')
      .Width(160)
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayTotal', 'Total')
      .Width(100)
      .ClassName('with-text-align-right')
      .AddSorter('Number')
      .AddCurrencyFilterer()
      .AddRenderer('Currency', true)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayStatus', 'Status')
      .Width(110)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayStatus).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayComment', 'Comment')
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
  ];

  // [actualStatus, displayStatus] representing the combos where each button should show up
  const approveStatuses = [['approved', 'warning'],['unapproved', 'unapproved'], ['posted', 'posted']];
  const voidStatuses = ['unapproved'];
  const unapproveStatusesWithAllJournal = [['posted', 'posted'], ['approved', 'warning'],['voided', 'voided'], ['unapproved', 'unapproved']];
  const unapproveStatusesWithoutAllJournal = [['voided', 'voided'], ['unapproved', 'unapproved']];
  const transactions = tableData.filter(x => selectedKeys.includes(x.transactionId));
  const canApprove = transactions.length > 0 && transactions.every(x => approveStatuses.some(([a, b]) => a === x.actualStatus.toLowerCase() && b === x.displayStatus.toLowerCase())) && hasAllJournal(transactions);
  const canVoid = transactions.length > 0 && transactions.every(x => voidStatuses.includes(x.actualStatus.toLowerCase()));
  //of all the cases which are special the on in which the unapprove button appears is especially special
  /*const canUnapprove = transactions.length > 0 && ((transactions.every(x => unapproveStatusesWithAllJournal.some(([a, b]) => a === x.actualStatus.toLowerCase() && b === x.displayStatus.toLowerCase())) && hasAllJournal(transactions)) 
  || transactions.every(x => unapproveStatusesWithoutAllJournal.some(([a, b]) => a === x.actualStatus.toLowerCase() && b === x.displayStatus.toLowerCase())));*/
  const canUnapprove = transactions.length > 0 && transactions.every(x => transactionCanBeUnapproved(x));

  function transactionCanBeUnapproved(transaction: ReportTransactionDTO){
    if(transaction.actualStatus.toLowerCase() === 'unapproved' && transaction.displayStatus.toLowerCase() === 'unapproved'){
      return true;
    }
    else if(transaction.actualStatus.toLowerCase() === 'voided' && transaction.displayStatus.toLowerCase() === 'voided')
    {
      return true;
    }
    else if(transaction.displayStatus.toLowerCase() !== 'approved'){
      let ret = true;
      //if the status is unapproved or voided we can let that in even if not everything is selected
      transactions.filter(x => x.actualStatus.toLocaleLowerCase() !== 'voided' && x.actualStatus.toLowerCase() !== 'unapproved').forEach(element => {
        //a list of transactions which are in the same journal as the current posted item
        let journal = tableData.filter(x => x.journalId === element.journalId);
        //check that each member of that journal is selected
        journal.forEach(x =>{
          if(!transactions.includes(x)){
            ret = false;
          }
        });
        
      });
      return ret;
    }
    return false;
  }

  function hasAllJournal(selected: ReportTransactionDTO[]){
    let ret = true;

    //check that for every item each member of thier journal is selected
    selected.filter(x => x.actualStatus.toLocaleLowerCase() !== 'voided').forEach(element => {
      //a list of transactions which are in the same journal as the current posted item
      let journal = tableData.filter(x => x.journalId === element.journalId);
      //check that each member of that journal is selected
      journal.forEach(x =>{
        if(!selected.includes(x)){
          ret = false;
        }
      });

    });
    
    return ret;
  }

  const ApproveTooltip = 'Approving the selected transaction(s) will result in the transaction(s) being sent to your Accounting System to be posted.';
  const VoidTooltip = 'Voiding the selected transaction(s) will exclude them from being posted to your Accounting System.';
  const UnapproveTooltip = 'Unapproving the selected transaction(s) will change the status of the transaction(s) back to Unapproved and remove all edits that have been made to the transaction.';
  const handleTableChange = (pagination: any, filters: Record<string, FilterValue | null>, sorter: any, extra: { currentDataSource: Array<ReportTransactionDTO> }) => {
    //When printing we want to just show the table as it was with sorts filters and all
    setTransactionTableFiltersQueryParam(JSON.stringify(filters));
    setTransactionSortedColumnQueryParam(sorter.field);
    setTransactionSortOrderQueryParam(sorter.order);
  };

  return (
    <div className='report-transaction'>
      <Row justify='space-between' style={{ marginBottom: 16}}>
        <Col>
          <p style={{width: 800}}>This report offers the ability to edit, approve, void, and unapprove transactions individually, or in bulk. Expand a transaction to view the lines that make up the transaction. Please keep in mind that changes made in this tab will not be reflected in the Shop Management System, but will be reflected in the Accounting System, upon approval.</p>
        </Col>

        <Row justify='end'>
          <Space>
            {canApprove && <Tooltip mouseEnterDelay={1} title={ApproveTooltip}><Button type='primary' className='green' style={{ height: 36 }} onClick={handleApproveClick} ><Space><ApproveIconSVG /><span>Approve</span></Space></Button></Tooltip>}
            {canVoid && <Tooltip mouseEnterDelay={1} title={VoidTooltip}><Button type='primary' className='red' style={{ height: 36 }} onClick={handleVoidClick} ><Space><VoidIconSVG /><span>Void</span></Space></Button></Tooltip>}
            {canUnapprove && <Tooltip mouseEnterDelay={1} title={UnapproveTooltip}><Button type='primary' style={{ height: 36 }} onClick={handleUnapproveClick} ><Space><ResetIconSVG /><span>Unapprove</span></Space></Button></Tooltip>}
            {renderPrintButton()}
          </Space>
        </Row>
      </Row>
      {renderExpandCollapseAll()}
      <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={refreshTableData}><Space><span>Refresh</span></Space></Button>
      {!loading && renderClearAllFilter()}
      <Table
        rowKey={NameOf<ReportTransactionDTO>('transactionId')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={filteredTableData}
        onChange={handleTableChange as any}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeys,
          onChange: (keys) => setSelectedKeys(keys)
        }}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: keys => (setExpandedKeys([...keys])),
          expandIcon: (props) => {
            const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
            return props.expanded ? <DownOutlined onClick={onClick} className='standard-chevrons' /> : <RightOutlined onClick={onClick} className='standard-chevrons' />;
          },
          expandedRowRender: record => (<div style={{ marginLeft: 30 } }>
            <ReportTransactionExpander  data={record} onChange={handleExpanderOnChange} />
          </div>)
        }}
      />
    </div>
  );
};

export default ReportTransactions;
