import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { NameOf } from 'src/utils';

//shamelessly / shamefully stolen from sageintacctconnect model
export interface NetSuiteConnectModalProps {

    // if the modal should be shown
    open: boolean;

    // function to run when the user clicks 'cancel' or clicks outside of the modal area
    onCancel?: () => void

    // function to run when the user clicks 'save'
    onSubmit: (formData: any) => void

}

interface NetSuiteConnectForm {
    accountId: string,
    consumerKey: string,
    consumerSecret: string,
    accessToken: string,
    accessTokenSecret: string
}

const NetSuiteConnectModal: React.FC<NetSuiteConnectModalProps> = (props) => {

    const [form] = Form.useForm<NetSuiteConnectForm>();
    const [formDisabled, setFormDisabled] = useState(true);

    // Because the submit button is outside of the form, we have to watch the state of the fields
    // in order to know when to disable the submit button (or any input validation)
    const accountId = Form.useWatch(NameOf<NetSuiteConnectForm>('accountId'), form);
    const consumerKey = Form.useWatch(NameOf<NetSuiteConnectForm>('consumerKey'), form);
    const consumerSecret = Form.useWatch(NameOf<NetSuiteConnectForm>('consumerSecret'), form);
    const accessToken = Form.useWatch(NameOf<NetSuiteConnectForm>('accessToken'), form);
    const accessTokenSecret = Form.useWatch(NameOf<NetSuiteConnectForm>('accessTokenSecret'), form);

    const formVals = [accountId, consumerKey, consumerSecret, accessToken, accessTokenSecret];

    useEffect(() => {
        setFormDisabled(!formVals.every(val => val && val.length > 0));
    }, formVals);

    const onReset = () => {
        form.resetFields();
    };

    const handleSubmit = () => {
        props.onSubmit(form.getFieldsValue());
        form.resetFields();
    };

    return (
        <Modal
            destroyOnClose
            open={props.open}
            width={600}
            onCancel={props.onCancel}
            footer={<>
                <Button type='default' onClick={props.onCancel}>Cancel</Button>
                <Button type='default' onClick={onReset}>Reset</Button>
                <Button type='primary' onClick={handleSubmit} disabled={formDisabled}>Save</Button>
            </>}>
            <h2>Connect to NetSuite</h2>
            <Form
                size={'large'}
                layout='horizontal'
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <Form.Item
                    label={'Account Id'}
                    name={NameOf<NetSuiteConnectForm>('accountId')}
                    rules={[{ required: true, message: 'Account Id is required' }]}>
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={'Consumer Key'}
                    name={NameOf<NetSuiteConnectForm>('consumerKey')}
                    rules={[{ required: true, message: 'Consumer Key is required' }]}>
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={'Consumer Secret'}
                    name={NameOf<NetSuiteConnectForm>('consumerSecret')}
                    rules={[{ required: true, message: 'Consumer Secret is required' }]}>
                    <Input.Password allowClear />
                </Form.Item>
                <Form.Item
                    label={'Access Token'}
                    name={NameOf<NetSuiteConnectForm>('accessToken')}
                    rules={[{ required: true, message: 'Access Token is required' }]}>
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={'Access Token Secret'}
                    name={NameOf<NetSuiteConnectForm>('accessTokenSecret')}
                    rules={[{ required: true, message: 'Access Token Secret is required' }]}>
                    <Input.Password allowClear />
                </Form.Item>               
            </Form>
        </Modal>);

};

export default NetSuiteConnectModal;