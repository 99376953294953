import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Collapse, Modal, Row, Skeleton, Space, Typography } from 'antd';
import { NotificationUtil, TableExpressions } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import AuditTrailItemDTO from 'src/models/generated/AuditTrailItemDTO';
import AuditController from 'src/api/AuditController';
import AuditTrailHistoryItem from './AuditTrailHistoryItem';
import LinkWithQuery from '../LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from '../svgs/PrintIconSVG';
import moment from 'moment';
import { tz } from 'moment-timezone';
export interface AuditTrailItemModalProps {
    open: boolean;
    auditItem: AuditTrailItemDTO | null;

    onCancel?: () => void;
}

const AuditTrailItemModal: React.FC<AuditTrailItemModalProps> = (props) => {
    const authContext = useContext(AuthenticationContext);
    const [modalData, setModalData] = useState<AuditTrailItemDTO[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);

    // This will reset the form when the visibility or defaultValues have changed
    useEffect(() => {
        // This modal can be reused MANY times so we need to clear it out... this should probably be the standard practice for modals 😅
        if (!props.open) {
            unloadModalData();
            return;
        }
        loadModalData();
    }, [props.open]);

    const loadModalData = async () => {
        setLoading(true);
        try {
            const results = await AuditController.getAuditTrailItemHistory(authContext.account!.id, authContext.location!.id, props.auditItem!.id);
            const sortedData = [...results.data.auditTrailItems].sort(TableExpressions.DateSorter('created')).reverse();

            setModalData(sortedData);
        } catch (error) {
            NotificationUtil.error({
                key: 'AuditTrailItemModal',
                message: 'Error while loading Filter Criteria',
                error
            });
        }
        setLoading(false);
    };

    const unloadModalData = () => {
        setModalData([]);
        setExpandedKeys([]);
        setLoading(false);
    };

    const handleModalCancel = () => {
        props.onCancel && props.onCancel();
    };

    const renderPrintButton = () => {
        const url = new URL(location.href);
        url.searchParams.delete('route_id');
        const printingQueryParams: [string, string][] = [
            ['auditItemId', props.auditItem?.id ?? ''],
            ['returnUrl', url.pathname + url.search],
            ['expandedIds', JSON.stringify(expandedKeys)],
        ];

        return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_AUDIT_TRAIL()} additionalQueryParams={printingQueryParams}>
            <Button style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
        </LinkWithQuery>;
    };

    return <Modal
        destroyOnClose // Indicates that we do not want to cache anything when reopening the modal
        className='audit-trail-item-modal'
        style={{ top: 100 }}
        bodyStyle={{ padding: '24px 48px' }} // Makes the modal JUST a bit cleaner
        width={99999} // Endgame boss health right here :D
        open={props.open}
        onCancel={handleModalCancel}
        footer={<>
            <Button type='primary' onClick={handleModalCancel}>Close</Button>
        </>}
    >
        <Row justify='space-between' align='middle' className='with-margin-bottom'>
            <Col><h2 className='without-margin-bottom'>{props.auditItem?.type}</h2></Col>
            <Col>{renderPrintButton()}</Col>
        </Row>
        {loading && <Skeleton active />}
        {!loading && <div className='with-flex-layout'>
            <Collapse
                activeKey={expandedKeys}
                onChange={keys => (setExpandedKeys([...keys]))}
            >
                {modalData.map((x, i) => (
                    <Collapse.Panel
                        key={i}
                        style={{ marginBottom: 4 }}
                        header={<div><Typography.Text strong>{x.created?.format('ll LTS ') + tz(tz.guess()).zoneAbbr()} - {x.userDisplayName}</Typography.Text>: {x.name}</div>}
                    >
                        <AuditTrailHistoryItem auditTrailItem={x} />
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>}
    </Modal>;
};

export default AuditTrailItemModal;
